import { CompanyType } from '@finverity/graphql';
import { environment } from '@finverity/platform-app/env/environment';

import { NavigationMenuPermissions } from '../identity-access';
import {
  NavigationSidebarMenuBottomSectionItem,
  NavigationSidebarMenuItemIdentifier,
  NavigationSidebarMenuSection,
  NavigationSidebarMenuSectionIdentifier,
} from '../types';

export const NAVIGATION_SIDEBAR_MENU_SECTIONS: NavigationSidebarMenuSection[] = [
  {
    sectionIdentifier: NavigationSidebarMenuSectionIdentifier.Home,
    title: 'Home',
    menuItems: [
      {
        identifier: NavigationSidebarMenuItemIdentifier.Dashboard,
        title: 'Dashboard',
        route: '/dashboard',
        disabled: false,
        iconName: 'fvIconNavMenuDashboard',
        permission: NavigationMenuPermissions.ViewDashboard,
      },
    ],
  },
  {
    sectionIdentifier: NavigationSidebarMenuSectionIdentifier.InstrumentUpload,
    title: 'Instrument Upload',
    menuItems: [
      {
        identifier: NavigationSidebarMenuItemIdentifier.InstrumentUpload,
        title: 'Upload Instruments',
        route: '/instrument-upload',
        disabled: false,
        iconName: 'fvIconNavMenuInstrumentUpload',
        permission: NavigationMenuPermissions.ViewInstrumentUpload,
      },
    ],
  },
  {
    sectionIdentifier: NavigationSidebarMenuSectionIdentifier.PayerApproval,
    title: 'Payer Approval',
    menuItems: [
      {
        identifier: NavigationSidebarMenuItemIdentifier.InstrumentApproval,
        title: 'Approve Instruments',
        disabled: false,
        iconName: 'fvIconNavMenuInstrumentApproval',
        permission: NavigationMenuPermissions.InstrumentApproval,
        subMenuItems: [
          {
            identifier: NavigationSidebarMenuItemIdentifier.PayerApprovalMaker,
            title: 'Maker Approval',
            route: 'payer-approval-maker',
            disabled: false,
            permission: NavigationMenuPermissions.ApproveRejectInstrumentsPayerApprovalMaker,
          },
          {
            identifier: NavigationSidebarMenuItemIdentifier.PayerApprovalChecker,
            title: 'Checker Approval',
            route: 'payer-approval-checker',
            disabled: false,
            permission: NavigationMenuPermissions.ApproveRejectInstrumentsPayerApprovalChecker,
          },
          {
            identifier: NavigationSidebarMenuItemIdentifier.PayerApprovalRejected,
            title: 'Rejected',
            route: 'payer-approval-rejected',
            disabled: false,
            permission: NavigationMenuPermissions.ManageRejectedInstrumentsPayerApproval,
          },
          {
            identifier: NavigationSidebarMenuItemIdentifier.MarkAsPaidWithoutFinancing,
            title: 'Paid Without Finance',
            route: 'mark-as-paid-without-financing',
            disabled: false,
            permission: NavigationMenuPermissions.MarkAsPaidWithoutFinancing,
          },
        ],
      },
    ],
  },
  {
    sectionIdentifier: NavigationSidebarMenuSectionIdentifier.RequestFinance,
    title: 'Request Finance',
    menuItems: [
      {
        identifier: NavigationSidebarMenuItemIdentifier.RequestAsSeller,
        title: 'Request as Seller',
        disabled: false,
        iconName: 'fvIconNavMenuFinanceRequest',
        permission: NavigationMenuPermissions.FinanceRequest,
        subMenuItems: [
          {
            identifier: NavigationSidebarMenuItemIdentifier.SellerFinanceRequestMaker,
            title: 'Submit Finance Request (Maker)',
            route: 'seller-finance-request-maker',
            disabled: false,
            permission: NavigationMenuPermissions.ApproveRejectInstrumentsSellerFinanceRequestMaker,
          },
          {
            identifier: NavigationSidebarMenuItemIdentifier.SellerFinanceRequestChecker,
            title: 'Approve Finance Request (Checker)',
            route: 'seller-finance-request-checker',
            disabled: false,
            permission: NavigationMenuPermissions.ApproveRejectInstrumentsSellerFinanceRequestChecker,
          },
          {
            identifier: NavigationSidebarMenuItemIdentifier.SellerFinanceRequestRejected,
            title: 'Rejected',
            route: 'seller-finance-request-rejected',
            disabled: false,
            permission: NavigationMenuPermissions.ManageRejectedInstrumentsSellerFinanceRequest,
          },
        ],
      },
      {
        identifier: NavigationSidebarMenuItemIdentifier.RequestAsPayer,
        title: 'Request as Payer',
        disabled: false,
        iconName: 'fvIconNavMenuFinanceRequest',
        permission: NavigationMenuPermissions.FinanceRequest,
        subMenuItems: [
          {
            identifier: NavigationSidebarMenuItemIdentifier.PayerFinanceRequestMaker,
            title: 'Submit Finance Request (Maker)',
            route: 'payer-finance-request-maker',
            disabled: false,
            permission: NavigationMenuPermissions.ApproveRejectInstrumentsPayerFinanceRequestMaker,
          },
          {
            identifier: NavigationSidebarMenuItemIdentifier.PayerFinanceRequestChecker,
            title: 'Approve Finance Request (Checker)',
            route: 'payer-finance-request-checker',
            disabled: false,
            permission: NavigationMenuPermissions.ApproveRejectInstrumentsPayerFinanceRequestChecker,
          },
          {
            identifier: NavigationSidebarMenuItemIdentifier.PayerFinanceRequestRejected,
            title: 'Rejected',
            route: 'payer-finance-request-rejected',
            disabled: false,
            permission: NavigationMenuPermissions.ManageRejectedInstrumentsPayerFinanceRequest,
          },
        ],
      },
      {
        identifier: NavigationSidebarMenuItemIdentifier.RejectedByFunder,
        title: 'Rejected by Funder',
        route: 'funder-approval-rejected',
        disabled: false,
        iconName: 'tuiIconXCircleLarge',
        permission: NavigationMenuPermissions.ManageFunderRejectedInstrumentsCustomer,
        companyTypes: [CompanyType.Customer],
      },
    ],
  },
  {
    sectionIdentifier: NavigationSidebarMenuSectionIdentifier.FinanceRequestWorkflow,
    title: 'Finance Requests',
    menuItems: [
      {
        identifier: NavigationSidebarMenuItemIdentifier.FinanceApproval,
        title: 'Approve for Funding',
        disabled: false,
        iconName: 'fvIconNavMenuFinanceApproval',
        permission: NavigationMenuPermissions.FinanceApproval,
        subMenuItems: [
          {
            identifier: NavigationSidebarMenuItemIdentifier.FunderApprovalMaker,
            title: 'Maker Approval',
            route: 'funder-approval-maker',
            disabled: false,
            permission: NavigationMenuPermissions.ApproveRejectInstrumentsFunderApprovalMaker,
          },
          {
            identifier: NavigationSidebarMenuItemIdentifier.FunderApprovalChecker,
            title: 'Checker Approval',
            route: 'funder-approval-checker',
            disabled: false,
            permission: NavigationMenuPermissions.ApproveRejectInstrumentsFunderApprovalChecker,
          },
          {
            identifier: NavigationSidebarMenuItemIdentifier.FunderApprovalRejected,
            title: 'Rejected',
            route: 'funder-approval-rejected',
            disabled: false,
            iconName: 'tuiIconXCircleLarge',
            permission: NavigationMenuPermissions.ManageRejectedInstrumentsFunderApproval,
            companyTypes: [CompanyType.Funder],
          },
        ],
      },
    ],
  },
  {
    sectionIdentifier: NavigationSidebarMenuSectionIdentifier.Repayments,
    title: 'Repayments',
    menuItems: [
      {
        identifier: NavigationSidebarMenuItemIdentifier.OutstandingInstruments,
        title: 'Outstanding Instruments',
        route: '/outstanding-instruments',
        disabled: false,
        iconName: 'fvIconNavMenuOutstandingInstruments',
        permission: NavigationMenuPermissions.ViewOutstandingInstruments,
      },
      {
        identifier: NavigationSidebarMenuItemIdentifier.Repayments,
        title: 'Repayments',
        route: '/repayments',
        disabled: false,
        iconName: 'fvIconNavMenuRepayments',
        permission: NavigationMenuPermissions.ViewRepayments,
      },
    ],
  },
  {
    sectionIdentifier: NavigationSidebarMenuSectionIdentifier.DisbursementAndReconciliation,
    title: 'Disbursements & Reconciliation',
    menuItems: [
      {
        identifier: NavigationSidebarMenuItemIdentifier.ReadyForFunding,
        title: 'Ready for Funding',
        route: '/ready-for-funding',
        disabled: false,
        iconName: 'fvIconNavMenuReadyForFunding',
        permission: NavigationMenuPermissions.InstrumentFunding && NavigationMenuPermissions.FundInstruments,
      },
      {
        identifier: NavigationSidebarMenuItemIdentifier.Disbursements,
        title: 'Disbursements',
        disabled: false,
        iconName: 'fvIconNavMenuDisbursements',
        permission: NavigationMenuPermissions.ViewDisbursementsSection,
        subMenuItems: [
          {
            identifier: NavigationSidebarMenuItemIdentifier.DisbursementConfirmation,
            title: 'Disbursement Confirmation',
            route: '/disbursement-confirmation',
            disabled: false,
            permission: NavigationMenuPermissions.InstrumentDisbursements && NavigationMenuPermissions.FundInstruments,
          },
          {
            identifier: NavigationSidebarMenuItemIdentifier.DisbursementFiles,
            title: 'Disbursement Files',
            route: '/disbursement-files',
            disabled: false,
            permission: NavigationMenuPermissions.ViewDisbursementFiles,
          },
        ],
      },
    ],
  },
  {
    sectionIdentifier: NavigationSidebarMenuSectionIdentifier.Payments,
    title: 'Payments',
    menuItems: [
      {
        identifier: NavigationSidebarMenuItemIdentifier.IncomingPayments,
        title: 'Incoming Payments',
        route: '/incoming-payments',
        disabled: false,
        iconName: 'fvIconNavMenuPayments',
        permission: NavigationMenuPermissions.ViewIncomingPayments,
      },
    ],
  },
  {
    sectionIdentifier: NavigationSidebarMenuSectionIdentifier.Administration,
    title: 'Administration',
    menuItems: [
      {
        identifier: NavigationSidebarMenuItemIdentifier.Companies,
        title: 'Companies',
        route: '/companies',
        matcher: routeUrl => routeUrl.startsWith('/company-onboarding/'),
        disabled: false,
        iconName: 'fvIconNavMenuCompanies',
        permission: NavigationMenuPermissions.ViewCompanies,
      },
      {
        identifier: NavigationSidebarMenuItemIdentifier.OnboardingForm,
        title: 'Onboarding Form',
        disabled: false,
        iconName: 'fvIconNavMenuOnboardingForm',
        permission: NavigationMenuPermissions.ManageOnboardingFormAndRules,
        companyTypes: [CompanyType.PortalAdmin],
        subMenuItems: [
          {
            identifier: NavigationSidebarMenuItemIdentifier.MasterRegistrationForm,
            title: 'Master Registration Form',
            route: '/master-registration-form',
            disabled: false,
            permission: NavigationMenuPermissions.ManageOnboardingFormAndRules,
          },
          {
            identifier: NavigationSidebarMenuItemIdentifier.ConditionalRules,
            title: 'Conditional Rules',
            route: '/conditional-rules',
            disabled: false,
            permission: NavigationMenuPermissions.ManageOnboardingFormAndRules,
          },
        ],
      },
      {
        identifier: NavigationSidebarMenuItemIdentifier.CampaignsManagement,
        title: 'Self Onboarding',
        route: '/self-onboarding',
        disabled: false,
        iconName: 'fvIconNavMenuSelfOnboarding',
        permission: NavigationMenuPermissions.ViewSelfOnboarding,
        companyTypes: [CompanyType.PortalAdmin],
      },
      {
        identifier: NavigationSidebarMenuItemIdentifier.Products,
        title: 'Products & Rates',
        disabled: false,
        iconName: 'fvIconNavMenuProducts',
        permission: NavigationMenuPermissions.ViewProducts,
        subMenuItems: [
          {
            identifier: NavigationSidebarMenuItemIdentifier.ProductGroups,
            title: 'Product Groups',
            route: '/product-group-management',
            disabled: false,
            permission: NavigationMenuPermissions.ViewProductGroups,
          },
          {
            identifier: NavigationSidebarMenuItemIdentifier.ProductStructures,
            title: 'Product Structures',
            route: '/product-structure-management',
            disabled: false,
            permission: NavigationMenuPermissions.ViewProductStructures,
          },
          {
            identifier: NavigationSidebarMenuItemIdentifier.FloatingRates,
            title: 'Floating Rates',
            route: '/floating-rate-management',
            disabled: false,
            permission: NavigationMenuPermissions.ViewFloatingRates,
          },
          {
            identifier: NavigationSidebarMenuItemIdentifier.FXRates,
            title: 'FX Rates',
            route: '/fx-rate-management',
            disabled: false,
            permission: NavigationMenuPermissions.ViewFXRates,
          },
        ],
      },
      {
        identifier: NavigationSidebarMenuItemIdentifier.ProgrammesAndDeals,
        title: 'Programmes & Deals',
        disabled: false,
        iconName: 'fvIconNavMenuProgrammesAndDeals',
        permission: NavigationMenuPermissions.ViewProgrammesAndDeals,
        subMenuItems: [
          {
            identifier: NavigationSidebarMenuItemIdentifier.Programmes,
            title: 'Programmes',
            route: '/programmes',
            disabled: false,
            permission: NavigationMenuPermissions.ViewProgrammes,
          },
          {
            identifier: NavigationSidebarMenuItemIdentifier.Deals,
            title: 'Deals',
            route: '/deals',
            matcher: routeUrl => routeUrl.startsWith('/deal/'),
            disabled: false,
            permission: NavigationMenuPermissions.ViewDeals,
          },
        ],
      },
      // {
      //   identifier: NavigationSidebarMenuItemIdentifier.Notifications,
      //   title: 'Notifications',
      //   route: '/notifications',
      //   disabled: false,
      //   iconName: 'tuiIconBellLarge',
      //   permission: NavigationMenuPermissions.ViewNotifications,
      // },
    ],
  },
  {
    sectionIdentifier: NavigationSidebarMenuSectionIdentifier.CompanyManagement,
    title: 'Company Management',
    menuItems: [
      {
        identifier: NavigationSidebarMenuItemIdentifier.CustomerCompanyDetails,
        title: 'Company Details',
        route: '/company-details',
        iconName: 'fvIconCompanyDetails',
        disabled: false,
        permission: NavigationMenuPermissions.CustomerViewCompanyDetails,
        companyTypes: [CompanyType.Customer],
      },
      {
        identifier: NavigationSidebarMenuItemIdentifier.CompanyNotifications,
        title: 'Company Notifications',
        titleForHeader: 'Company Notification Settings',
        route: '/company-notifications',
        iconName: 'fvIconNavMenuNotificationSettings',
        disabled: false,
        permission: NavigationMenuPermissions.ViewCompanyNotifications,
      },
      {
        identifier: NavigationSidebarMenuItemIdentifier.UserManagement,
        title: 'User Management',
        route: '/user-management',
        iconName: 'fvIconNavMenuUserManagement',
        disabled: false,
        permission: NavigationMenuPermissions.ViewUserManagement,
      },
      {
        // TODO: Change icon when it'll appear
        identifier: NavigationSidebarMenuItemIdentifier.AuditTrail,
        title: 'Audit Trail',
        route: '/audit-trail',
        iconName: 'tuiIconVoicemailLarge',
        disabled: false,
        permission: NavigationMenuPermissions.ViewAuditTrail,
        companyTypes: [CompanyType.PortalAdmin],
      },
      {
        identifier: NavigationSidebarMenuItemIdentifier.CompanySsoSettings,
        title: 'Company SSO Settings',
        route: '/sso/company-settings',
        iconName: 'fvIconSso',
        disabled: false,
        permission: NavigationMenuPermissions.ViewCompanySsoSettings,
      },
    ],
  },
];

export const NAVIGATION_SIDEBAR_MENU_SECTIONS_ORDERING_FOR_CUSTOMERS: NavigationSidebarMenuSectionIdentifier[] = [
  NavigationSidebarMenuSectionIdentifier.Home,
  NavigationSidebarMenuSectionIdentifier.InstrumentUpload,
  NavigationSidebarMenuSectionIdentifier.FinanceRequestWorkflow,
  NavigationSidebarMenuSectionIdentifier.PayerApproval,
  NavigationSidebarMenuSectionIdentifier.RequestFinance,
  NavigationSidebarMenuSectionIdentifier.Repayments,
  NavigationSidebarMenuSectionIdentifier.DisbursementAndReconciliation,
  NavigationSidebarMenuSectionIdentifier.Payments,
  NavigationSidebarMenuSectionIdentifier.Administration,
  NavigationSidebarMenuSectionIdentifier.CompanyManagement,
];

export const NAVIGATION_SIDEBAR_MENU_SECTIONS_ORDERING_FOR_FUNDERS: NavigationSidebarMenuSectionIdentifier[] = [
  NavigationSidebarMenuSectionIdentifier.Home,
  NavigationSidebarMenuSectionIdentifier.InstrumentUpload,
  NavigationSidebarMenuSectionIdentifier.FinanceRequestWorkflow,
  NavigationSidebarMenuSectionIdentifier.PayerApproval,
  NavigationSidebarMenuSectionIdentifier.RequestFinance,
  NavigationSidebarMenuSectionIdentifier.DisbursementAndReconciliation,
  NavigationSidebarMenuSectionIdentifier.Repayments,
  NavigationSidebarMenuSectionIdentifier.Administration,
  NavigationSidebarMenuSectionIdentifier.CompanyManagement,
];

export const NAVIGATION_SIDEBAR_MENU_SECTIONS_ORDERING_FOR_ADMINS: NavigationSidebarMenuSectionIdentifier[] = [
  NavigationSidebarMenuSectionIdentifier.Home,
  NavigationSidebarMenuSectionIdentifier.InstrumentUpload,
  NavigationSidebarMenuSectionIdentifier.FinanceRequestWorkflow,
  NavigationSidebarMenuSectionIdentifier.PayerApproval,
  NavigationSidebarMenuSectionIdentifier.RequestFinance,
  NavigationSidebarMenuSectionIdentifier.DisbursementAndReconciliation,
  NavigationSidebarMenuSectionIdentifier.Repayments,
  NavigationSidebarMenuSectionIdentifier.Administration,
  NavigationSidebarMenuSectionIdentifier.CompanyManagement,
];

export const NAVIGATION_SIDEBAR_MENU_BOTTOM_SECTION_ITEMS: Array<NavigationSidebarMenuBottomSectionItem> = [
  {
    identifier: NavigationSidebarMenuItemIdentifier.Changelog,
    title: 'Changelog',
    href: 'https://headwayapp.co/finverity-changelog',
    disabled: false,
    iconName: 'fvIconNavMenuChangelog',
  },
  {
    identifier: NavigationSidebarMenuItemIdentifier.Help,
    title: 'Help',
    href: environment.platform.helpLink,
    disabled: false,
    iconName: 'fvIconNavMenuHelp',
  },
];
